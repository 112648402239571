import axios from "axios";
import { apiInstanceFetch } from "../../../util/api";
import { secretKey } from "../../../util/config";
import { setToast } from "../../../util/toast";
import * as ActionType from "./seller.type";

// GET SELLER

export const getSeller = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`seller/getRealSeller?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_SELLER,
        payload: res.sellers,
        totalSeller: res.totalSellers,
      });
    })
    .catch((error) => console.error(error));
};

export const sellerDropDown = () => (dispatch) => {
  apiInstanceFetch
    .get("seller/getSellerAddedByAdmin")
    .then((res) => {
      dispatch({
        type: ActionType.GET_SELLER_DROP_DOWN,
        payload: res.seller,
      });
    })
    .catch((error) => console.error(error));
};

// SELLER BLOCK UNBLOCK

export const sellerIsBlock = (seller, block) => (dispatch) => {
  axios
    .patch(`seller/blockUnblock?sellerId=${seller._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.BLOCK_UNBLOCK_SELLER,
          payload: { data: res.data.seller, id: seller._id },
        });
        setToast(
          "success",
          `${seller.firstName} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", "Seller Unblock Successfully !!");
      }
    })
    .catch((error) => setToast("error", error));
};

export const loginSeller = (formData) => (dispatch) => {
  axios
    .post("seller/loginSeler", formData, {
      headers: {
        "Content-Type": "application/json",
        key: secretKey,
      },
    })
    .then((res) => {
      if (res.data.status) {
        console.log(res.data);
        dispatch({
          type: ActionType.LOGIN_SELLER_SUCCESS,
          payload: res.data,
        });
        setToast("success", "Login Successfully!");
      } else {
        dispatch({
          type: ActionType.LOGIN_SELLER_ERROR,
          payload: res.data.message,
        });
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      dispatch({ type: ActionType.LOGIN_SELLER_ERROR, payload: error });
      setToast("error", error.message);
    });
};

export const signupSeller = (formData) => (dispatch) => {
  console.log(formData);
  axios.post("request/byWeb", formData,  ).then((res) => {
    if (res.data.status) {
      dispatch({
        type: ActionType.SIGNUP_SELLER,
        payload: res.data.status,
      });
      setToast("success", "Đăng ký thành công chờ admin duyệt");
    } else {
      setToast("error", res.data.message);
    }
  });
};

// CREATE SELLER

export const createSeller = (formData) => (dispatch) => {
  axios
    .post("seller/createByAdmin", formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ActionType.CREATE_SELLER, payload: res.data.seller });
        setToast("success", "Seller  created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

// UPDATE SELLER

export const updateSeller = (formData, id) => (dispatch) => {
  axios
    .patch(`seller/update?sellerId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_SELLER,
          payload: { updateSeller: res.data.seller, id },
        });

        setToast("success", "Seller Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
      console.log(error);
    });
};

// GET SELLER PROFILE

export const getSellerProfile = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`seller/getProfile?sellerId=${id}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_SELLER_PROFILE,
        payload: res.seller,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

// SELLER ORDER

export const getSellerProduct = (sellerId) => (dispatch) => {
  apiInstanceFetch
    .get(`product/getSellerWise?sellerId=${sellerId}`)
    .then((res) => {
      console.log(res.product);
      dispatch({
        type: ActionType.GET_SELLER_PRODUCT,
        payload: res.product,
      });
    })
    .catch((error) => console.error(error));
};

// Get Seller Wallet

export const getSellerWallet = (sellerId) => (dispatch) => {
  apiInstanceFetch
    .get(`sellerWallet/getAllAmount?sellerId=${sellerId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_SELLER_WALLET,
        payload: res,
      });
    })
    .catch((error) => console.error(error));
};
// Get Seller Transition

export const getSellerTransition =
  (sellerId, startDate, endDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `sellerWallet/sellerTransaction?sellerId=${sellerId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_SELLER_TRANSITION,
          payload: res.transactions,
        });
      })
      .catch((error) => console.error(error));
  };
export const getSellerOrder =
  (sellerId, start, limit, status) => (dispatch) => {
    apiInstanceFetch
      .get(
        `order/ordersOfSeller?sellerId=${sellerId}&start=${start}&limit=${limit}&status=${status}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.GET_SELLER_ORDER,
            payload: res.orders,
          });
        }
      })
      .catch((error) => console.error(error));
  };
export const getSellerOrderDetail = (sellerId, orderId) => (dispatch) => {
  apiInstanceFetch
    .get(`order/orderForSeller?sellerId=${sellerId}&orderId=${orderId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.GET_SELLER_ORDER_DETAIL,
          payload: res.order,
        });
      }
    })
    .catch((error) => console.error(error));
};
export const updateSellerOrder =
  (userId, orderId, status, itemId, data) => (dispatch) => {
    axios
      .patch(
        !data
          ? `order/updateOrder?userId=${userId}&orderId=${orderId}&status=${status}&itemId=${itemId}`
          : `order/updateOrder?userId=${userId}&orderId=${orderId}&status=${status}&itemId=${itemId}`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.UPDATE_SELLER_ORDER,
            payload: {
              updateOrder: res.data.data,
              userId,
              orderId,
              status,
              itemId,
            },
          });
          setToast("success", "Order Update Successfully!");
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => setToast("error", error));
  };

// live seller

export const getLiveSeller = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`liveSeller/liveSellerList?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_LIVE_SELLER,
        payload: res.liveSeller,
      });
    })
    .catch((error) => console.error(error));
};

// get live seller product
export const getLiveSellerProduct = (sellerId) => (dispatch) => {
  apiInstanceFetch
    .get(`product/selectedProducts?sellerId=${sellerId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_LIVE_SELLER_PRODUCT,
        payload: res.SelectedProducts,
      });
    })
    .catch((error) => console.error(error));
};

// get reel by seller by id

export const getReelBySeller = (sellerId) => (dispatch) => {
  apiInstanceFetch
    .get(`reel/reelsOfSeller?sellerId=${sellerId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_REEL_BY_SELLER,
        payload: res.reels,
      });
    })
    .catch((error) => console.error(error));
};
export const uploadReel = (formData) => (dispatch) => {
  axios
    .post("reel/uploadReel", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPLOAD_REEL,
          payload: res.data.reel,
        });
        setToast("success", "Reel Upload Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};
export const deleteReelBySeller = (reelId) => (dispatch) => {
  axios
    .delete(`reel/deleteReel?reelId=${reelId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_REEL,
          payload: reelId,
        });
        setToast("success", "Reel Deleted Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};

export const getReelDetail = (reelId) => (dispatch) => {
  apiInstanceFetch
    .get(`reel/detailsOfReel?reelId=${reelId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_REEL_DETAIL,
        payload: res.reel,
      });
    })
    .catch((error) => console.error(error));
};

export const addProductSeller = (formData) => (dispatch) => {
  axios
    .post("product/create", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.ADD_PRODUCT_SELLER,
          payload: res.data.product,
        });
        setToast("success", "Product Added Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};

export const updateProductSeller =
  (formData, id, sellerId, productCode) => (dispatch) => {
    axios
      .patch(
        `product/update?productId=${id}&sellerId=${sellerId}&productCode=${productCode}`,
        formData
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.UPDATE_PRODUCT_SELLER,
            payload: { data: res.data.product, id },
          });
          setToast("success", "Product Updated Successfully");
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => console.error(error));
  };

export const deleteProductSeller = (productId) => (dispatch) => {
  axios
    .delete(`product/delete?productId=${productId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_PRODUCT_SELLER,
          payload: productId,
        });
        setToast("success", "Product Deleted Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};

export const changeCollection = (product, collection) => (dispatch) => {
  axios
    .patch(`product/isNewCollection?productId=${product}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.PRODUCT_NEW_COLLECTION,
          payload: { data: res.data.product, id: collection._id },
        });

        setToast(
          "success",
          `${collection?.seller?.firstName} Is ${
            res.data.product.isNewCollection == true
              ? "Add New Collection"
              : "Remove New Collection"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

export const changeOutOfStock = (product, stock) => (dispatch) => {
  axios
    .patch(`product/isOutOfStock?productId=${product}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.PRODUCT_OUT_OF_STOCK,
          payload: { data: res.data.product, id: stock._id },
        });

        setToast(
          "success",
          `${stock?.seller?.firstName} Is ${
            stock.isOutOfStock == true
              ? "Instock Product"
              : "Out Of Stock Product"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

export const getPromoCode = (sellerId) => (dispatch) => {
  apiInstanceFetch
    .get(`promoCode/getByIdSeller?bySeller=${sellerId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_PROMO_CODE,
        payload: res.promoCode,
        totalPromoCode: res.totalPromoCode,
      });
    })
    .catch((error) => console.error(error));
};

export const createPromoCode = (data, sellerId) => (dispatch) => {
  axios
    .post(`promoCode/create?bySeller=${sellerId}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_PROMO_CODE,
          payload: res.data.promoCode,
        });
        setToast("success", "PromoCode Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const updatePromoCode = (data, id, sellerId) => (dispatch) => {
  axios
    .post(`promoCode/update?promoCodeId=${id}&bySeller=${sellerId}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_PROMO_CODE,
          payload: { data: res.data.promoCode, id },
        });
        setToast("success", "PromoCode update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

export const deletePromoCode = (id, sellerId) => (dispatch) => {
  axios
    .delete(`promoCode/delete?promoCodeId=${id}&bySeller=${sellerId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_PROMO_CODE,
          payload: id,
        });
        setToast("success", "PromoCode Deleted Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
};
