import axios from "axios";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import Button from "../extra/Button";
import Input from "../extra/Input";
import { signupSeller } from "../store/seller/seller.action";
import {
  getSetting,
  handleSentOTP,
  handleVerifyOTP,
} from "../store/setting/setting.action";

const RegistrationSeller = (props) => {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [address, setAddress] = useState("");

  const [businessName, setBusinessName] = useState("");
  const [businessTag, setBusinessTag] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBusinessName, setBankBusinessName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [countries, setCountries] = useState("");
  const [states, setStates] = useState("");
  const [cities, setCities] = useState("");
  const isSignUp = useSelector((state) => state.seller.isSignUp);
  const [listProvince, setListProvince] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [listCity, setListCIty] = useState([]);

  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();
  const [logoPath, setlogoPath] = useState();
  const [bannerPath, setBannerPath] = useState();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    gender: "",
    email: "",
    password: "",
    confirmPassword: "",
    imagePath: "",
    address: "",

    countries: "",
    states: "",
    cities: "",

    businessName: "",
    businessTag: "",
    bankName: "",
    bankBusinessName: "",
    accountNumber: "",
    otp: "",
    branchName: "",
  });

  const handleSubmit = () => {
    if (isVerify == false) {
      return setError({
        ...error,
        mobileNumber: `Vui lòng Xác thực otp `,
      });
    }

    if (
      !firstName ||
      !lastName ||
      !mobileNumber ||
      !image ||
      mobileNumber?.length < 0 ||
      mobileNumber?.length > 10 ||
      !email ||
      !password ||
      !confirmPassword ||
      !password?.length >= 8 ||
      !confirmPassword?.length >= 8 ||
      !address ||
      !cities ||
      !states ||
      !countries ||
      !businessName ||
      !businessTag ||
      password !== confirmPassword
    ) {
      let error = {};
      if (!firstName) error.firstName = "First Name Is Required ";
      if (!lastName) error.lastName = "Last Name Is Required ";
      if (!mobileNumber) error.mobileNumber = "Mobile Number Is Required ";
      if (mobileNumber?.length > 10)
        error.mobileNumber = "Invalid Mobile Number...";
      if (image?.length === 0 || !imagePath) error.image = "Image is required!";
      if (password.length < 8) {
        error.password = "Mật khẩu phải lớn hơn 8 ký tự";
      }
      if (confirmPassword.length < 8) {
        error.confirmPassword = "Mật khẩu phải lớn hơn 8 ký tự";
      }
      if (!email) error.email = "Email Is Required ";
      if (!password) error.password = "Password Is Required ";
      if (password !== confirmPassword)
        error.confirmPassword =
          "Confirm Password Is doesn't match to Password ";

      if (!address) error.address = "Address Is Required ";

      if (!cities) error.cities = "city Is Required ";
      if (!states) error.states = "State Is Required ";
      if (!countries) error.countries = "Country Is Required ";

      if (!businessName) error.businessName = "Business Name Is Required ";
      if (!businessTag) error.businessTag = "Business Tag Is Required ";

      return setError({ ...error });
    } else {
      const formData = new FormData();

      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("mobileNumber", mobileNumber);
      formData.append("email", email);
      formData.append("password", confirmPassword);
      formData.append("image", image);
      formData.append("gender", gender);

      formData.append("businessName", businessName);
      formData.append("businessTag", businessTag);
      formData.append("bankName", bankName);
      formData.append("bankBusinessName", bankBusinessName);
      formData.append("accountNumber", accountNumber);

      formData.append("country", countries);
      formData.append("state", states);
      formData.append("city", cities);
      if (logo != null || logo != "" || logo != undefined) {
        formData.append("logo", logo);
      }
      if (banner != null || banner != "" || banner != undefined) {
        formData.append("banner", banner);
      }
      formData.append("address", address);
      formData.append("loginType", 3);
      formData.append("identity", "3");
      formData.append("fcmToken", "3");

      props.signupSeller(formData);
    }
  };
  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);
  useEffect(() => {
    if (isSignUp) {
      navigate("/login-seller");
    }
  }, [isSignUp, navigate]);
  const handleBack = () => {
    window.history.back();
  };
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await axios.get(
          "https://vapi.vnappmob.com/api/province/"
        ); // Thay đổi URL với API thực tế
        setListProvince(response.data.results);
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProvinces();
  }, []);

  const handleProvinceChange = async (event) => {
    let name = event.target.value;
    setCities(event.target.value);
    setCountries("");

    const foundProvince = listProvince.find(
      (province) => province.province_name === name
    );
    if (name) {
      try {
        const response = await axios.get(
          `https://vapi.vnappmob.com/api/province/district/${foundProvince.province_id}`
        ); // Thay đổi URL với API thực tế
        setListCIty(response.data.results);
        console.log(response.data.results);
      } catch (err) {
        console.log(err);
      }
    } else {
    }
  };
  const handleChangeState = async (e) => {
    setStates(e.target.value);
    let name = e.target.value;
    const found = listCity.find((city) => city.district_name === name);
    if (name) {
      try {
        const response = await axios.get(
          `https://vapi.vnappmob.com/api/province/ward/${found.district_id}`
        ); // Thay đổi URL với API thực tế
        setListWard(response.data.results);
        console.log(response.data.results);
      } catch (err) {
        console.log(err);
      }
    } else {
    }
  };

  const handleChangeLogo = (e) => {
    setLogo(e.target.files[0]);
    setlogoPath(URL.createObjectURL(e.target.files[0]));
  };
  const handleChangeBanner = (e) => {
    setBanner(e.target.files[0]);
    setBannerPath(URL.createObjectURL(e.target.files[0]));
  };
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+84" + mobileNumber;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        setError({
          ...error,
          mobileNumber: "",
        });
      })
      .catch((error) => {
        console.log(error);
        setError({
          ...error,
          mobileNumber: "Chọn số điện thoại không hợp lệ",
        });
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setLoading(false);
        setIsVerify(true);
        setShowOTP(false);
        setError({
          ...error,
          mobileNumber: (
            <div className="text-success">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{
                  width: 30,
                  color: "green",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                />
              </svg>
              Xác thực thành công
            </div>
          ),
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <div className="mainSellerDialog container">
      <div className="sellerDialog">
        <div className="sellerHeader primeHeader">
          <div className="row ">
            <div className="my-4  d-flex justify-content-center align-items-center">
              <div className="loginLogo ">
                <img src={`/${setting.appLogo}`} alt="" width={"80px"} />
              </div>
            </div>
          </div>
        </div>
        <div className="sellerMain" style={{ margin: "10px 18px" }}>
          <div className="card">
            <div className="card-body">
              <div className="sellerDetail pt-3">
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw-bolder mb-4">Đăng ký nhà bán hàng</h2>
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Tên`}
                      placeholder={`First Name`}
                      id={`firstName`}
                      type={`text`}
                      value={firstName}
                      errorMessage={error.firstName && error.firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            firstName: `First Name Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            firstName: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Họ`}
                      placeholder={`last Name`}
                      id={`lastName`}
                      type={`text`}
                      value={lastName}
                      errorMessage={error.lastName && error.lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            lastName: `LastName Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            lastName: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-9">
                        <Input
                          label={`Số điện thoại`}
                          placeholder={`Mobile Number`}
                          id={`mobileNumber`}
                          type={`number`}
                          value={mobileNumber}
                          errorMessage={
                            error.mobileNumber && error.mobileNumber
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            setMobileNumber(e.target.value);
                            if (
                              /^\d*$/.test(value) &&
                              value.length <= 10 &&
                              value.length >= 9
                            ) {
                              setMobileNumber(value);
                            } else {
                              return setError({
                                ...error,
                                mobileNumber: `Số điện thoại không hợp lệ`,
                              });
                            }
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                mobileNumber: `Mobile Number Is Required`,
                              });
                            } else {
                              return setError({
                                ...error,
                                mobileNumber: "",
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Button
                          newClass={`whiteFont ms-3`}
                          disabled={isVerify}
                          btnColor={`btnBlackPrime`}
                          btnName={loading ? "Đang gửi" : "Gửi mã"}
                          onClick={onSignup}
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        />
                      </div>
                    </div>

                    {showOTP && (
                      <div className="row align-items-center">
                        <div className="col-9">
                          <Input
                            label={`Otp`}
                            placeholder={`123123`}
                            id={`otp`}
                            type={`number`}
                            errorMessage={error.otp && error.otp}
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  otp: `Vui lòng nhập OTP`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  otp: "",
                                });
                              }
                            }}
                          />
                        </div>

                        <div className="col-3 mt-2">
                          {isVerify == true ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.0"
                              stroke="currentColor"
                              style={{
                                width: 30,
                                color: "green",
                              }}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                              />
                            </svg>
                          ) : (
                            <Button
                              newClass={`whiteFont ms-3`}
                              btnColor={`btnBlackPrime`}
                              disabled={isVerify}
                              btnName={`Xác nhận`}
                              onClick={onOTPVerify}
                              style={{
                                borderRadius: "10px",
                                border: "1px solid red",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Email`}
                      placeholder={`Email`}
                      id={`email`}
                      type={`text`}
                      value={email}
                      errorMessage={error.email && error.email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            email: `Email Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            email: "",
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <Input
                      label={`Mật khẩu`}
                      placeholder={`Password`}
                      id={`password`}
                      type={`password`}
                      value={password}
                      errorMessage={error.password && error.password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            password: `Password Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            password: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Xác nhận mật khẩu`}
                      placeholder={`Confirm Password`}
                      id={`confirmPassword`}
                      type={`password`}
                      value={confirmPassword}
                      errorMessage={
                        error.confirmPassword && error.confirmPassword
                      }
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            confirmPassword: `Confirm Password Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            confirmPassword: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Ảnh`}
                      id={`sellerImage`}
                      type={`file`}
                      accept={`image/*`}
                      errorMessage={error.image && error.image}
                      onChange={(e) => handleUploadImage(e)}
                    />
                    {imagePath && (
                      <>
                        <img
                          src={imagePath}
                          className="ms-2 mb-4"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "10px",
                          }}
                          alt=""
                          srcSet=""
                        />
                      </>
                    )}
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="prime-input mb-2">
                      <label className="mb-3"> Select Gender </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Input
                        label={`Nữ`}
                        name={`gender`}
                        id={`male`}
                        type={`radio`}
                        value={`male`}
                        checked={(gender == "male" || gender == "Male") && true}
                        newClass={`me-3`}
                        onChange={(e) => {
                          setGender(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              gender: `Gender Is Required`,
                            });
                          } else {
                            return setError({
                              ...error,
                              gender: "",
                            });
                          }
                        }}
                      />
                      <Input
                        label={`Nam`}
                        name={`gender`}
                        id={`female`}
                        type={`radio`}
                        value={`female`}
                        checked={
                          (gender == "female" || gender == "Female") && true
                        }
                        onChange={(e) => {
                          setGender(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              gender: `Gender Is Required`,
                            });
                          } else {
                            return setError({
                              ...error,
                              gender: "",
                            });
                          }
                        }}
                      />
                    </div>
                    {error.gender && (
                      <>
                        <p className="errorMessage">{error.gender}</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw-bolder my-4">Thông tin cửa hàng</h2>
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Logo`}
                      id={`sellerImage`}
                      type={`file`}
                      accept={`image/*`}
                      onChange={(e) => handleChangeLogo(e)}
                    />
                    {logoPath && (
                      <>
                        <img
                          src={logoPath}
                          className="ms-2 mb-4"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "10px",
                          }}
                          alt=""
                          srcSet=""
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Banner`}
                      id={`sellerImage`}
                      type={`file`}
                      accept={`image/*`}
                      onChange={(e) => handleChangeBanner(e)}
                    />
                    {bannerPath && (
                      <>
                        <img
                          src={bannerPath}
                          className="ms-2 mb-4"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "10px",
                          }}
                          alt=""
                          srcSet=""
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Tên doanh nghiệp`}
                      placeholder={`Business Name`}
                      id={`businessName`}
                      type={`text`}
                      value={businessName}
                      errorMessage={error.businessName && error.businessName}
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            businessName: `Business Name Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            businessName: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Slogan doanh nghiệp`}
                      placeholder={`Business Tag`}
                      id={`Bussinesstage`}
                      type={`text`}
                      value={businessTag}
                      errorMessage={error.businessTag && error.businessTag}
                      onChange={(e) => {
                        setBusinessTag(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            businessTag: `Bussiness Tage Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            businessTag: "",
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <label
                      htmlFor="provinceSelect"
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      Tỉnh Thành Phố
                    </label>
                    <select
                      value={cities}
                      onChange={handleProvinceChange}
                      className="form-select"
                      id="city"
                    >
                      <option value="" disabled>
                        -- Chọn tỉnh thành phố --
                      </option>
                      {listProvince.map((province) => (
                        <option
                          key={province.province_id}
                          value={province.province_name}
                        >
                          {province.province_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <label
                      htmlFor="provinceSelect"
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      Huyện, quận
                    </label>
                    <select
                      value={states}
                      onChange={handleChangeState}
                      className="form-select"
                      id={`State`}
                    >
                      <option value="" disabled>
                        -- Chọn quận huyện --
                      </option>
                      {listCity.map((city) => (
                        <option
                          key={city.district_id}
                          value={city.district_name}
                        >
                          {city.district_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <label
                      htmlFor="provinceSelect"
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      Xã phường
                    </label>
                    <select
                      value={countries}
                      onChange={(e) => {
                        setCountries(e.target.value);
                      }}
                      className="form-select"
                      id={`country`}
                    >
                      <option value="" disabled>
                        -- Chọn Xã phường --
                      </option>
                      {listWard.map((ward) => (
                        <option key={ward.ward_id} value={ward.ward_name}>
                          {ward.ward_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Địa chỉ`}
                      placeholder={`Address`}
                      id={`address`}
                      type={`text`}
                      value={address}
                      errorMessage={error.address && error.address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            address: `Address Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            address: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw-bolder my-4">Thông tin tài khoản</h2>
                  </div>

                  <div className="col-md-6 col-12">
                    <Input
                      label={`Tên chủ tài khoản`}
                      placeholder={`Bank Bussiness Name`}
                      id={`Bank Bussiness Name`}
                      type={`text`}
                      value={bankBusinessName}
                      errorMessage={
                        error.bankBusinessName && error.bankBusinessName
                      }
                      onChange={(e) => {
                        setBankBusinessName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            bankBusinessName: `Bank Bussiness Name Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            bankBusinessName: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Input
                      label={`Tên ngân hàng`}
                      placeholder={`Bank Name`}
                      id={`bankName`}
                      type={`text`}
                      value={bankName}
                      errorMessage={error.bankName && error.bankName}
                      onChange={(e) => {
                        setBankName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            bankName: `Bank Name Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            bankName: "",
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <Input
                      label={`Số tài khoản`}
                      placeholder={`Account Number`}
                      id={`accountNumber`}
                      type={`number`}
                      value={accountNumber}
                      errorMessage={error.accountNumber && error.accountNumber}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            accountNumber: `Account Number Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            accountNumber: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Button
                      newClass={`themeFont mx-2 btn `}
                      btnColor={`btn-outline-danger`}
                      btnName={`Trở về trang đăng nhập`}
                      onClick={handleBack}
                      style={{
                        borderRadius: "10px",
                        border: "1px solid red",
                      }}
                    />
                    <Button
                      newClass={`themeFont mx-2 btn`}
                      btnColor={`btn-outline-success`}
                      btnName={`Hoàn tất đăng ký`}
                      onClick={handleSubmit}
                      style={{
                        borderRadius: "10px",
                        border: "1px solid green",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sellerFooter primeFooter">
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  signupSeller,
  handleSentOTP,
  handleVerifyOTP,
})(RegistrationSeller);
