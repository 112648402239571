import * as ActionType from "./setting.type";
const initialState = {
  setting: [],
  isTrue: false,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SETTING:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.UPDATE_SETTING:
      return {
        ...state,
      };
    //Handle Update Switch Value
    case ActionType.HANDLE_TOGGLE_SWITCH:
      return {
        ...state,
        setting: action.payload.setting,
      };
    case ActionType.UPDATE_APP_NAME:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.UPDATE_APP_LOGO:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.UPDATE_APP_BANNER:
      return {
        ...state,
        setting: action.payload,
      };

    case ActionType.UPDATE_CHANGE_KEY_SMS:
      return {
        ...state,
        setting: action.payload,
      };

    case ActionType.SENT_OTP_PHONE:
      return {
        ...state,
      };

    case ActionType.VERIFY_OTP_PHONE:
      return {
        ...state,
        isTrue: action.payload,
      };

    default:
      return state;
  }
};
