import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../Component/extra/Button";
import { getSellerProfile } from "../Component/store/seller/seller.action";

const ProfilePage = () => {
  const sellerProfile = useSelector((state) => state.seller.sellerProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let id = localStorage.getItem("id");
    dispatch(getSellerProfile(id));
  }, [dispatch]);
  return (
    <>
      <div className="mainSellerProfile">
        <div className="sellerProfile">
          <div className="sellerProfileHeader primeHeader">
            <div className="row mt-4">
              <div className="col-xl-4 col-md-6 col-12 pb-3 pe-3">
                <div className="card" style={{ borderRadius: "5px" }}>
                  <div className="card-body">
                    <div className="boxCenter p-3">
                      <img
                        src={sellerProfile?.image}
                        width={120}
                        height={120}
                        style={{ borderRadius: "15%" }}
                        alt=""
                      />
                    </div>

                    <div className="fw-bold boxCenter">
                      {sellerProfile?.firstName
                        ? sellerProfile?.firstName
                        : " "}{" "}
                      {sellerProfile?.lastName ? sellerProfile?.lastName : " "}
                    </div>
                    <div className="px-2 mt-4">
                      <div className="row mb-4">
                        <div className="col-6 boxEnd">
                          <div className="d-flex">
                            <div className="boxCenter"></div>
                            <div className="ms-2">
                              <p className="mb-1 text-center fw-bold">
                                {sellerProfile?.followers}
                              </p>
                              <p className="text-p mb-0 font-weight-regular">
                                Followers
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="d-flex">
                            <div className="boxCenter"></div>
                            <div className="ms-2">
                              <p className="mb-1 text-center fw-bold">
                                {sellerProfile?.following}
                              </p>
                              <p className="text-p mb-0 font-weight-regular">
                                Following
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <table className="w-100 mt-2">
                      <tbody>
                        <tr className="text-start">
                          <td width="90px" className="py-3  text-profile">
                            Email
                          </td>
                          <td width="30px">:</td>
                          <td className="text-start">{sellerProfile?.email}</td>
                        </tr>
                        <tr className="text-start">
                          <td width="90px" className="py-3  text-profile">
                            Tên doanh nghiệp
                          </td>
                          <td width="30px">:</td>
                          <td className="text-start">
                            {sellerProfile?.businessName}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <td width="90px" className="py-3  text-profile">
                            Slogan
                          </td>
                          <td width="30px">:</td>
                          <td className="text-start">
                            {sellerProfile?.businessTag}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <td width="90px" className="py-3 text-profile ">
                            Số điện thoại
                          </td>
                          <td width="30px">:</td>
                          <td className="text-capitalize text-start">
                            {sellerProfile?.mobileNumber}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <td width="90px" className="py-3  text-profile">
                            Địa chỉ
                          </td>
                          <td width="30px">:</td>
                          <td className="text-start">
                            {sellerProfile?.address.address
                              ? sellerProfile?.address?.address + " "
                              : " "}
                            {sellerProfile?.address.landMark
                              ? sellerProfile?.address?.landMark
                              : " "}
                          </td>
                        </tr>

                        <tr className="text-start">
                          <td width="180px" className="py-3 text-profile ">
                            Tên ngân hàng
                          </td>
                          <td width="30px">:</td>
                          <td className="text-capitalize text-start">
                            {sellerProfile?.bankDetails.bankName}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <td width="180px" className="py-3 text-profile ">
                            Số tài khoản
                          </td>
                          <td width="30px">:</td>
                          <td className="text-capitalize text-start">
                            {sellerProfile?.bankDetails.accountNumber}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <td width="180px" className="py-3 text-profile ">
                            Tên chủ tài khoản
                          </td>
                          <td width="30px">:</td>
                          <td className="text-capitalize text-start">
                            {sellerProfile?.bankDetails.bankBusinessName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-md-6  col-12 pb-3 ps-2">
                <div className="card" style={{ borderRadius: "5px" }}>
                  <div className="heading2 d-flex justify-content-between py-2 px-3">
                    <h5 className="fw-bold fs-6  ms-2 mb-0">
                      {sellerProfile?.firstName
                        ? sellerProfile?.firstName + " "
                        : " "}
                      {sellerProfile?.lastName
                        ? sellerProfile?.lastName + "'s"
                        : ""}{" "}
                    </h5>
                    <div className="row">
                      <Button
                        btnName={`Edit`}
                        btnColor={`bg-danger text-white `}
                        style={{ borderRadius: "5px", width: "80px" }}
                        onClick={() => {
                            navigate("/seller/profile/edit");
                        }}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-6 col-md-12 mt-3">
                        <div className=" p-3 d-flex flex-column">
                          <label className="styleForTitle mb-1 text-dark">
                            Banner
                          </label>
                          <img
                            src={sellerProfile?.banner}
                            width={120}
                            height={120}
                            style={{ borderRadius: "15%" }}
                            alt="AẢnh Banner"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-12 mt-3">
                        <div className="  p-3 d-flex flex-column">
                          <label className="styleForTitle mb-1 text-dark">
                            Logo
                          </label>
                          <img
                            src={sellerProfile?.logo}
                            width={120}
                            height={120}
                            style={{ borderRadius: "15%" }}
                            alt="AẢnh Logo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
