import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const isSeller = localStorage.getItem("isSeller");
  const token = localStorage.getItem("tokenSeller");

  return token && isSeller ? <Outlet /> : <Navigate to="/login-seller" />;
};

export default PrivateRoute;
