import React, { useEffect, useState } from "react";
import SettingBox from "./SettingBox";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSetting, handleChangeKeySMS } from "../../store/setting/setting.action";

 const ThirtSetting = (props) => {
  const [apiKey, setApiKey] = useState();
  const [secretKey, setSecretKey] = useState();
  const dispatch = useDispatch();

  const { setting } = useSelector((state) => state.setting);
  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);
  const handleChangeApikey = (e) => {
    setApiKey(e.target.value);
  };
  const handleSubmitKey = () => {
    console.log(apiKey, secretKey);
    const data={
        apiKey,
        secretKey
    }

    props.handleChangeKeySMS(data)
  };
  const handleChangeSecretKey = (e) => {
    setSecretKey(e.target.value);
  };

  return (
    <>
      <div className="mainSettingBar">
        <div className="settingBar ">
          <div className="settingHeader primeHeader">
            <div className="row align-items-center">
              <div className="col-6"></div>
              <div className="col-6 text-end"></div>
            </div>
          </div>
          <div className="settingMain">
            <div className="row">
              <SettingBox
                title={`Cài đặt sms`}
                bottom={true}
                onChange={handleSubmitKey}
              >
                <Input
                  label={`Api Key`}
                  id={`nameApp`}
                  type={`text`}
                  name={"nameApp"}
                  value={apiKey}
                  onChange={(e) => handleChangeApikey(e)}
                />
                <Input
                  label={`Secret Key`}
                  id={`nameApp`}
                  type={`text`}
                  name={"nameApp"}
                  value={secretKey}
                  onChange={(e) => handleChangeSecretKey(e)}
                />
              </SettingBox>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null,{handleChangeKeySMS})(ThirtSetting)