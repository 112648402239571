import jwt_decode from "jwt-decode";
import { secretKey } from "../../../util/config";
import { SetDevKey, setToken } from "../../../util/setAuth";
import * as ActionType from "./seller.type";
const initialState = {
  seller: [],
  totalSeller: 0,
  sellerWallet: {},
  isSeller: false,
  product: null,
  reels: [],
  totalReels: 0,
  sellerOrder: [],
  promoCode: [],
  isSignUp: false,
};

export const sellerReducer = (state = initialState, action) => {
  let decode;
  switch (action.type) {
    case ActionType.GET_SELLER:
      return {
        ...state,
        seller: action.payload,
        totalSeller: action.totalSeller,
      };
    case ActionType.GET_SELLER_DROP_DOWN:
      return {
        ...state,
        seller: action.payload,
      };
    case ActionType.BLOCK_UNBLOCK_SELLER:
      console.log(action.payload, ":::SELLER");
      return {
        ...state,
        seller: state.seller.map((sellerBlock) =>
          sellerBlock._id === action.payload.id
            ? { ...sellerBlock, isBlock: action.payload.data.isBlock }
            : sellerBlock
        ),
        sellerProfile: action.payload.data,
      };
    case ActionType.CREATE_SELLER:
      let data = [...state.seller];
      console.log(action.payload);
      data.unshift(action.payload);

      return {
        ...state,
        seller: data,
      };
    case ActionType.UPDATE_SELLER:
      return {
        ...state,
        seller: state.seller.map((data) =>
          data._id === action.payload.updateSeller._id
            ? action.payload.updateSeller
            : data
        ),
      };
    case ActionType.GET_SELLER_PROFILE:
      return {
        ...state,
        sellerProfile: action.payload,
      };
    case ActionType.GET_SELLER_PRODUCT:
      console.log(action.payload);
      return {
        ...state,
        product: action.payload,
      };
    case ActionType.GET_SELLER_WALLET:
      return {
        ...state,
        sellerWallet: action.payload,
      };
    case ActionType.GET_SELLER_TRANSITION:
      return {
        ...state,
        sellerTransition: action.payload,
      };
    case ActionType.GET_SELLER_ORDER:
      console.log(action.payload);
      return {
        ...state,
        sellerOrder: action.payload,
      };
    case ActionType.GET_SELLER_ORDER_DETAIL:
      return {
        ...state,
        sellerOrderDetail: action.payload,
      };
    case ActionType.GET_LIVE_SELLER:
      return {
        ...state,
        liveSeller: action.payload,
      };
    case ActionType.GET_LIVE_SELLER_PRODUCT:
      return {
        ...state,
        liveProduct: action.payload,
      };
    case ActionType.LOGIN_SELLER_SUCCESS:
      if (action.payload) {
        decode = jwt_decode(action.payload.token);
      }
      // Set Token And Key In Axios
      setToken(action.payload.token);
      SetDevKey(secretKey);
      localStorage.setItem("id", action.payload.id);
      localStorage.setItem("tokenSeller", action.payload.token);
      localStorage.setItem("key", secretKey);
      localStorage.setItem("isSeller", true);
      return {
        ...state,
        sellers: decode,
        isSeller: true,
      };
    case ActionType.LOGIN_SELLER_ERROR:
      return {
        ...state,
        isSeller: false,
      };
    case ActionType.LOGOUT_SELLER:
      localStorage.removeItem("tokenSeller");
      localStorage.removeItem("key");
      localStorage.removeItem("isAuth");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("isSeller");
      return {
        ...state,
        isSeller: false,
      };
    case ActionType.GET_REEL_BY_SELLER:
      return {
        ...state,
        reels: action.payload,
        totalReels: action.payload.length,
      };
    case ActionType.UPLOAD_REEL:
      return {
        ...state,
        reels: [action.payload, ...state.reels],
      };
    case ActionType.DELETE_REEL:
      return {
        ...state,
        reels: state.reels.filter((reel) => reel._id !== action.payload),
      };
    case ActionType.GET_REEL_DETAIL:
      return {
        ...state,
        reelDetail: action.payload,
      };
    case ActionType.ADD_PRODUCT_SELLER:
      return {
        ...state,

        product: [...state.product, action.payload],
      };
    case ActionType.UPDATE_PRODUCT_SELLER:
      return {
        ...state,
        product: state.product.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_PRODUCT_SELLER:
      return {
        ...state,
        product: state.product.filter((data) => data._id !== action.payload),
      };
    case ActionType.PRODUCT_NEW_COLLECTION:
      return {
        ...state,
        product: state.product.map((Collection) => {
          if (Collection?._id === action.payload?.id)
            return action.payload.data;
          else return Collection;
        }),
      };
    case ActionType.PRODUCT_OUT_OF_STOCK:
      return {
        ...state,
        product: state.product.map((Stock) => {
          if (Stock?._id === action.payload.id) return action.payload.data;
          else return Stock;
        }),
      };
    case ActionType.GET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case ActionType.CREATE_PROMO_CODE:
      let promoCode = [...state.promoCode];
      promoCode.unshift(action.payload);
      return {
        ...state,
        promoCode: promoCode,
      };
    case ActionType.UPDATE_PROMO_CODE:
      return {
        ...state,
        promoCode: state.promoCode.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_PROMO_CODE:
      return {
        ...state,
        promoCode: state.promoCode.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    case ActionType.UPDATE_SELLER_ORDER:
      return {
        ...state,
        sellerOrder: state.sellerOrder.map((data) =>
          data._id === action.payload.updateOrder._id
            ? action.payload.updateOrder
            : data
        ),
      };
    case ActionType.SIGNUP_SELLER:
      return {
        ...state,
        isSignUp: action.payload,
      };
    default:
      return state;
  }
};
