import React from "react";
import Button from "../extra/Button";

const Notfound = () => {
  const handleSubmit = () => {
    window.history.back();
  };
  return (
    <>
      <div id="oopss">
        <div id="error-text">
          <img
            src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
            alt="404"
          />
          <span>404 PAGE</span>
          <p class="p-a">Tính năng đang phát triển </p>

          <Button
            newClass={`whiteFont ms-3`}
            btnColor={`btnBlackPrime`}
            style={{
              borderRadius: "20px",
              width: "170px",
              height: "46px",
            }}
            btnName={`Trở về trang trước`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default Notfound;
