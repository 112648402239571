//Get Setting
export const GET_SETTING = "GET_SETTING";

//Update Setting
export const UPDATE_SETTING = "UPDATE_SETTING";


//Toggle Switch
export const HANDLE_TOGGLE_SWITCH = "HANDLE_TOGGLE_SWITCH";
export const UPDATE_APP_NAME = "UPDATE_APP_NAME";
export const UPDATE_APP_LOGO = "UPDATE_APP_LOGO";
export const UPDATE_APP_BANNER = "UPDATE_APP_BANNER";

export const UPDATE_CHANGE_KEY_SMS="UPDATE_CHANGE_KEY_SMS"


export const SENT_OTP_PHONE="SENT_OTP_PHONE"
export const VERIFY_OTP_PHONE="VERIFY_OTP_PHONE"