import axios from "axios";
import { apiInstanceFetch } from "../../../util/api";
import { setToast } from "../../../util/toast";
import * as ActionType from "./setting.type";
export const getSetting = () => (dispatch) => {
  apiInstanceFetch
    .get(`setting`)
    .then((res) => {
      dispatch({ type: ActionType.GET_SETTING, payload: res.setting });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateSetting = (settingData, id) => (dispatch) => {
  axios
    .patch(`setting/update?settingId=${id}`, settingData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.UPDATE_SETTING,
          payload: res.data.setting,
        });
        setToast("success", "Setting Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Handle Toggle Switch
export const handleToggleSwitch = (id, type, setting) => (dispatch) => {
  axios
    .patch(`setting/handleSwitch?settingId=${id}&type=${type}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.HANDLE_TOGGLE_SWITCH,
          payload: { setting: res.data.setting, id },
        });
        console.log("type", type);
        if (type === "productRequest") {
          if (setting.isAddProductRequest === true) {
            setToast("success", `Add Product Request Switch Off Successfully!`);
          } else {
            setToast("success", `Add Product Request Switch On Successfully!`);
          }
        }

        if (type === "updateProductRequest") {
          if (setting.isUpdateProductRequest === true) {
            setToast(
              "success",
              `Update Product Request Switch Off Successfully!`
            );
          } else {
            setToast(
              "success",
              `Update Product Request Switch On Successfully!`
            );
          }
        }

        if (type === "isFakeData") {
          if (setting?.isFakeData === true) {
            setToast("success", `Fake Data Switch Off Successfully!`);
          } else {
            setToast("success", `Fake Data Switch On Successfully!`);
          }
        }
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const handleChangeAppName = (appName) => (dispatch) => {
  axios.post(`setting/appName?appName=${appName}`).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: ActionType.UPDATE_APP_NAME,
        payload: res.data.setting,
      });
      setToast("success", "App Name Update Successfully!");
    } else {
      setToast("error", res.data.message);
    }
  });
};

export const handleChangeAppLogo = (logo) => (dispatch) => {
  axios.post(`setting/appLogo`, logo).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: ActionType.UPDATE_APP_LOGO,
        payload: res.data.setting,
      });
      setToast("success", "App Logo Update Successfully!");
    } else {
      setToast("error", res.data.message);
    }
  });
};

export const handleChangeAppBanner = (banner) => (dispatch) => {
  axios.post(`setting/appBanner`, banner).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: ActionType.UPDATE_APP_BANNER,
        payload: res.data.setting,
      });
      setToast("success", "App Banner Update Successfully!");
    } else {
      setToast("error", res.data.message);
    }
  });
};

export const handleChangeKeySMS = (data) => (dispatch) => {
  axios.post("setting/changeSMS", data).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: ActionType.UPDATE_CHANGE_KEY_SMS,
        payload: res.data.setting,
      });
      setToast("success", "Change Key Successfully!");
    } else {
      setToast("error", res.data.message);
    }
  });
};

export const handleSentOTP = (data) => (dispatch) => {
  axios.post("otp/verifyPhone", data).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: ActionType.SENT_OTP_PHONE,
        payload: res.data,
      });
      setToast("success", res.data.messsage);
    } else {
      setToast("error", res.data.message);
    }
  });
};

export const handleVerifyOTP = (data) => (dispatch) => {
  axios.post("otp/checkOtpPhone", data).then((res) => {
    console.log(res.data.status)
    if (res.data.status === true) {
      dispatch({
        type: ActionType.VERIFY_OTP_PHONE,
        payload: res.data.isTrue,
      });
      setToast("success", res.data.messsage);
    } else {
      setToast("error", "Loi");
    }
  }).catch((err)=>{
    console.log(err)
    setToast("error", err.response.data.error);
  });
};
