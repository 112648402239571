import $ from "jquery";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import NavbarSeller from "./NavbarSeller";
import OrderPage from "./OrderPage";
import ProductPage from "./ProductPage";

import ProductDetail from "../Component/Table/Product/ProductDetail";
import ReelsInfo from "../Component/Table/reels/ReelsInfo";
import OrderDetailPage from "./OrderDetailPage";
import ProductAddPage from "./ProductAddPage";
import ProfilePage from "./ProfilePage";
import PromoCodePage from "./PromoCodePage";
import RedeemPage from "./RedeemPage";
import ReelsPage from "./ReelsPage";
import SidebarSeller from "./SidebarSeller";
import ProfileEditPage from "./ProfileEditPage";
import Notfound from "../Component/Pages/Notfound";

const SellerRouter = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".subMenu").hide();

      $(".mainMenu > li > a").click(function () {
        if ($(this).next(".subMenu").is(":visible")) {
          // If it's open, close it
          $(this).next(".subMenu").slideUp();
          $(this).children("i").removeClass("rotate90");
        } else {
          // If it's closed, close all other submenus and open the clicked one
          $(".subMenu").slideUp();
          $(".mainMenu > li > a").children("i").removeClass("rotate90");
          $(this).next(".subMenu").slideDown();
          $(this).children("i").addClass("rotate90");
        }
      });

      $(".navToggle").click(function () {
        $(".mainNavbar").toggleClass("mobNav webNav");
        $(".sideBar").toggleClass("mobSidebar webSidebar");
        $(".mainAdmin").toggleClass("mobAdmin");
      });
    });
  }, []);
  return (
    <>
      <div className="mainContainer d-flex w-100">
        <div className="containerLeft">
          <SidebarSeller />
        </div>
        <div className="containerRight w-100">
          <NavbarSeller />
          <div className="mainAdmin">
            <Routes>
              <Route path="/dashboard" element={<HomePage />} />
              <Route path="/order" element={<OrderPage />} />
              <Route path="/orderDetail" element={<OrderDetailPage />} />
              <Route path="/video" element={<ReelsPage />} />
              <Route path="/videoDetail" element={<ReelsInfo />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/productAdd" element={<ProductAddPage />} />
              <Route path="/productDetail" element={<ProductDetail />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/profile/edit" element={<ProfileEditPage />} />
              <Route path="/promoCode" element={<PromoCodePage />} />
              <Route path="/redeem" element={<RedeemPage />} />
              
              <Route path="*" element={<Notfound />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerRouter;
