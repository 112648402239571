import "./App.css";
import Login from "./Component/Pages/Login.js";

import { useDispatch, useSelector } from "react-redux";
import Admin from "./Component/Pages/Admin";

import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import LoginSeller from "./Component/Pages/LoginSeller.js";
import Notfound from "./Component/Pages/Notfound.js";
import Registration from "./Component/Pages/Registration.js";
import RegistrationSeller from "./Component/Pages/RegistrationSeller.js";
import { LOGIN_ADMIN } from "./Component/store/admin/admin.type";
import { LOGIN_SELLER_SUCCESS } from "./Component/store/seller/seller.type.js";
import { getSetting } from "./Component/store/setting/setting.action.js";
import SellerRouter from "./seller/SellerRouter.js";
import AuthRoute from "./util/AuthRoute";
import PrivateRoute from "./util/PrivateRoute.js";

function App() {
  const dispatch = useDispatch();
  const key = localStorage.getItem("key");
  const token = localStorage.getItem("token") || null;
  const tokenSeller = localStorage.getItem("tokenSeller");
  const id = localStorage.getItem("id");
  const [login, setLogin] = useState(false);
  const isSeller = localStorage.getItem("isSeller");
  useEffect(() => {
    axios
      .get("/login")
      .then((res) => {
        setLogin(res.data.login);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (token && key) {
      localStorage.removeItem("tokenSeller");
      localStorage.removeItem("isSeller");
      dispatch({ type: LOGIN_ADMIN, payload: token });
    }
  }, [key, token, dispatch]);
  useEffect(() => {
    console.log();
    if (isSeller === "true" && tokenSeller && key) {
      localStorage.removeItem("token");
      localStorage.removeItem("isAuth ");
      dispatch({
        type: LOGIN_SELLER_SUCCESS,
        payload: {
          token: tokenSeller,
          id: id,
        },
      });
    }
  }, [dispatch, id, isSeller, key, tokenSeller]);

  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    document.title = setting.appName;
  }, [setting.appName]);

  useEffect(() => {
    if (setting.appLogo) {
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = "/"+setting.appLogo;
      } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = setting.appLogo;
        document.head.appendChild(newLink);
      }
    }
  }, [setting.appLogo]);
  return (
    <div className="App">
      <Routes>
        {login === true && <Route path="/administrator" element={<Login />} />}
        {login === false && <Route path="/administrator/registrantion" element={<Registration />} />}
        {login && <Route path="/administrator" element={<Login />} />}

        <Route element={<AuthRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/seller/*" element={<SellerRouter />} />
        </Route>
        <Route path="/login-seller" element={<LoginSeller />} />
        <Route path="/registration-seller" element={<RegistrationSeller />} />
        {/* 404 not found */}
        {/* <Route path="*" element={<Notfound />} /> */}
      </Routes>
    </div>
  );
}

export default App;
