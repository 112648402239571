export const formatMoneyVND = (amount) => {
  if( amount==undefined){
    return "0 đ"
  }
  
  return amount.toLocaleString("vi-VN", { style: "currency", currency: "VND" });
};

export const generateThumbnailBlob = async (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      video.currentTime = 1; // Set to capture the frame at 1 second
    };

    video.onseeked = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to blob
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.8 // Adjust the quality of the JPEG image
      );
    };

    video.onerror = (error) => {
      reject(error);
    };

    const objectURL = URL.createObjectURL(file);
    video.src = objectURL;

    return () => {
      URL.revokeObjectURL(objectURL);
    };
  });
};
