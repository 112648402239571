import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import {
  getSetting,
  handleChangeAppBanner,
  handleChangeAppLogo,
  handleChangeAppName,
} from "../../store/setting/setting.action";
import SettingBox from "./SettingBox";
import { baseURL } from "../../../util/config";

const SettingBusiness = (props) => {
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [appName, setAppName] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [bannerPath, setBannerPath] = useState("");
  const [bannerPathOld, setBannerPathOld] = useState("");
  const [logoPathOld, setLogoPathOld] = useState("");
  const [change, setChange] = useState("")
  const dispatch = useDispatch();
  
  const { setting } = useSelector((state) => state.setting);
  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch, change]);

  const handleLogo = (e) => {
    setLogoPath(URL.createObjectURL(e.target.files[0]));
    setLogo(e.target.files[0]);
  };
  const handleBanner = (e) => {
    setBannerPath(URL.createObjectURL(e.target.files[0]));
    setBanner(e.target.files[0]);
  };
  const handleAppName = (e) => {
    console.log(e.target.value)
    setAppName(e.target.value);
  };
  const handleChangeAppName1 = (e) => {
    if (appName) {
      props.handleChangeAppName(appName);
      setChange("13");
    }
  };
  const handleChangeAppLogo1 = (e) => {
    if (logo) {
      let formData = new FormData();
      formData.append("image", logo);
      props.handleChangeAppLogo(formData);
      setChange("123");
    }
  };
  const handleChangeAppBanner1 = (e) => {
    if (banner) {
      let formData = new FormData();
      formData.append("image", banner);
      props.handleChangeAppBanner(formData);
      setChange("125");
      setBannerPathOld("https://noithatbinhminh.com.vn/wp-content/uploads/2022/08/anh-dep-40.jpg.webp")
    }
  };
  useEffect(() => {
    if (setting.appName != "") {
      setAppName(setting.appName)
      setLogoPathOld(baseURL + setting.appLogo)
      setBannerPathOld(baseURL + setting.appBanner)
      
    }
    console.log(123)
  }, [setting, change])

  return (
    <div className="mainSettingBar">
      <div className="settingBar ">
        <div className="settingHeader primeHeader">
          <div className="row align-items-center">
            <div className="col-6"></div>
            <div className="col-6 text-end"></div>
          </div>
        </div>
        <div className="settingMain">
          <div className="row">
            <SettingBox title={`Thay đổi logo`} bottom={true} onChange={handleChangeAppLogo1}>
              <Input
                label={`Logo`}
                id={`logo`}
                type={`file`}
                accept={`image/*`}
                newClass={"col-8"}
                onChange={(e) => handleLogo(e)}
              />
              <div className="image-start col-4">
                {logoPathOld && (
                  <img
                    src={logoPathOld}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0 img-fluid object-fit-cover"
                  />
                )}
              </div>
              <div className="col-12  ">

                {logoPath && (
                  <div className="image-start d-flex justify-content-between">
                    <img
                      src={logoPath}
                      alt="banner"
                      draggable="false"
                      width={100}
                      className="m-0 img-fluid"
                    />
                  </div>
                )}
              </div>

            </SettingBox>
            <SettingBox title={`Thay đổi banner`} bottom={true} onChange={handleChangeAppBanner1}>
              <Input
                label={`Banner`}
                id={`banner`}
                type={`file`}
                accept={`image/*`}
                newClass={"col-8"}
                onChange={(e) => handleBanner(e)}
              />
              <div className="image-start col-4">
                {bannerPathOld && (
                  <img
                    src={baseURL+setting.appBanner}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0 img-fluid object-fit-cover"
                  />
                )}
              </div>
              <div className="col-12  ">

                {bannerPath && (
                  <div className="image-start d-flex justify-content-between">
                    <img
                      src={bannerPath}
                      alt="banner"
                      draggable="false"
                      width={100}
                      className="m-0 img-fluid"
                    />
                  </div>
                )}
              </div>


            </SettingBox>
            <SettingBox title={`Tên của app`}>
              <Input
                label={`Tên của app`}
                id={`nameApp`}
                type={`text`}
                name={'nameApp'}
                value={appName}
                onChange={(e) => handleAppName(e)}
              />
              <div className="col-12 d-flex justify-content-end">
                <Button
                  newClass={`whiteFont`}
                  btnName={`Lưu`}
                  onClick={handleChangeAppName1}
                  btnColor={`btnBlackPrime`}
                  style={{ width: "90px", borderRadius: "6px" }}
                />
              </div>
            </SettingBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  handleChangeAppBanner,
  handleChangeAppName,
  handleChangeAppLogo,
})(SettingBusiness);
