import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Button from "../Component/extra/Button";
import Input from "../Component/extra/Input";
import { CLOSE_DIALOGUE } from "../Component/store/dialogue/dialogue.type";
import { updateFakeReel } from "../Component/store/fakeReels/fakeReels.action";
import {
  getSellerProduct,
  uploadReel,
} from "../Component/store/seller/seller.action";

const SellerReelDiague = (props) => {
  const dispatch = useDispatch();

  const { dialogueData } = useSelector((state) => state.dialogue);

  const { product } = useSelector((state) => state.seller);
  useEffect(() => {
    let sellerId = localStorage.getItem("id");
    dispatch(getSellerProduct(sellerId));
  }, [dispatch]);
  const [mongoId, setMongoId] = useState("");
  const [sellerType, setSellerType] = useState("");
  const [videoType, setVideoType] = useState(2);
  const [videoPath, setVideoPath] = useState(null);
  const [anh, setAnh] = useState(null);

  const [thumbnailPath, setThumbnailPath] = useState("");
  const [duration, setDuration] = useState(0);
  const [productType, setProductType] = useState("");

  const [thumbnailKey, setThumbnailKey] = useState(0);
  const [video, setVideo] = useState({
    file: "",
    thumbnailBlob: "",
  });
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);

  const [error, setError] = useState({
    video: "",
    videoPath: "",
    sellerType: "",
    productType: "",
    thumbnail: "",
    thumbnailPath: "",
  });

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setSellerType(dialogueData?.sellerId?._id);
      setVideoType(dialogueData?.videoType);
      setProductType(dialogueData?.productId?._id);
      setVideoPath(dialogueData?.video);
      setVideo(dialogueData?.video);
      setThumbnail(dialogueData?.thumbnail);
      setThumbnailPath(dialogueData?.thumbnail);
    }
  }, [dialogueData]);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setVideoSrc(videoURL);
      setVideoPath(file);
    }
  };
  const handleCapture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Thiết lập kích thước canvas bằng với kích thước video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Vẽ khung hiện tại của video lên canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Chuyển đổi canvas thành URL ảnh
    const dataURL = canvas.toDataURL("image/png");

    const uniqueFilename = `${uuidv4()}.png`;
    let file = null;
    canvas.toBlob((blob) => {
      file = new File([blob], uniqueFilename, { type: "image/jpeg" });
      setThumbnail(file);
    }, "image/jpeg");
    // Tạo tên file duy nhất

    setAnh({ file: file, url: dataURL });
  };

  const handleImage = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      thumbnail: "",
    }));
    setThumbnail(e.target.files[0]);
    setThumbnailPath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    console.log("videoType", anh);
    if (videoType == 1) {
      if (
        !productType ||
        productType == "" ||
        videoSrc == null ||
        anh == null
      ) {
        const errors = {};
        if (!productType || productType == "") {
          errors.productType = "Product is Required!";
        }
        if (videoSrc == null) {
          errors.video = "Video is Required!";
        }
        if (thumbnail == null) {
          errors.thumbnail = "Thumbnail is Required!";
        }
        if (anh == null) {
          errors.anh = "Thumbnail is Required!";
        }
        return setError({ ...errors });
      }
    } else {
      if (!productType || productType == "" || !videoPath) {
        const errors = {};
        if (!productType || productType == "") {
          errors.productType = "Product is Required!";
        }
        if (!videoPath) {
          errors.video = "Video is Required!";
        }
        if (!thumbnail) {
          errors.thumbnail = "Thumbnail is Required!";
        }
        return setError({ ...errors });
      }
    }
    const urlRegex = /^(ftp|http[s]?)?:\/\/[^ "]+$/;
    if (videoType == 2 && urlRegex.test(videoPath && dialogueData?.video)) {
      const errors = { video: "Invalid URL!" };
      return setError({ ...errors });
    }

    const formData = new FormData();

    formData.append("sellerId", sellerType || localStorage.getItem("id"));
    formData.append("productId", productType);

    if (mongoId && video.length === 0) {
      formData.append("videoType", videoType);
      formData.append("video", videoPath);
    } else {
      formData.append("videoType", videoType);
      formData.append("video", videoPath);
    }
    setError({});
    formData.append("thumbnail", thumbnail);
    formData.append("duration", duration);

    console.log({
      sellerId: sellerType || localStorage.getItem("id"),
      productId: productType,
      videoType: videoType,
      video: videoSrc,
      thumbnail: thumbnail,
      duration: duration,
    });
    if (mongoId) {
      props.uploadReel(formData, sellerType, mongoId);
    } else {
      props.uploadReel(formData);
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Tạo video</div>
            <div
              className="closeBtn "
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div
            className="dialogueMain"
            style={{
              overflowY: "auto",
              maxHeight: "80vh",
            }}
          >
            <div className="row">
              <div className="  col-12">
                <label className="styleForTitle mb-2 text-dark">Product</label>
                <select
                  productName="type"
                  className="form-control form-control-line"
                  id="type"
                  value={productType}
                  onChange={(e) => {
                    setProductType(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        productType: "Product is Required !",
                      });
                    } else {
                      return setError({
                        ...error,
                        productType: "",
                      });
                    }
                  }}
                >
                  <option value="" disabled selected>
                    --select product--
                  </option>
                  {product?.map((data) => {
                    return (
                      <option value={data?._id}>{data?.productName}</option>
                    );
                  })}
                </select>

                {error.productType && (
                  <div className="pl-1 text-left">
                    <p className="errorMessage">{error.productType}</p>
                  </div>
                )}
              </div>
              <div className="col-12">
                <label className="styleForTitle mb-2 text-dark my-2">
                  Thông tin chi tiết
                </label>
                <textarea className="form-control" rows="4" name="description">
                  {dialogueData?.description}
                </textarea>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center mt-3">
                  <label className="mb-3"> Video Type :- </label>
                  <Input
                    label={`File`}
                    name={`file`}
                    id={`file`}
                    type={`radio`}
                    value={"1"}
                    checked={videoType == 1 ? true : false}
                    newClass={`me-3 ms-2 mb-2`}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                  />
                  <Input
                    label={`Link`}
                    name={`file`}
                    id={`link`}
                    type={`radio`}
                    value={"2"}
                    newClass={`mb-2`}
                    checked={videoType == 2 ? true : false}
                    onClick={(e) => {
                      setVideoType(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={videoType == 1 ? "col-12" : "d-none"}>
                <Input
                  label={`Video`}
                  id={`video`}
                  type={`file`}
                  accept={`video/*`}
                  errorMessage={error.video && error.video}
                  onChange={handleVideoUpload}
                />
                <div className="row">
                  {videoSrc && (
                    <>
                      <video
                        ref={videoRef}
                        src={videoSrc}
                        style={{ width: "200px", height: "200px" }}
                        controls
                      />
                    </>
                  )}
                  {anh && (
                    <>
                      <img
                        alt="thumbnail"
                        src={anh.url}
                        style={{
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    </>
                  )}
                </div>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
              </div>
              {error.anh && (
                <div className="pl-1 text-left">
                  <p className="errorMessage">{error.anh}</p>
                </div>
              )}
              {videoType == 1 && videoSrc && (
                <div className="col-12 mt-4">
                  <Button
                    btnName={`Tạo Thumnail `}
                    btnColor={`btnBlackPrime text-white`}
                    style={{ borderRadius: "5px", width: "100%" }}
                    newClass={`me-2`}
                    onClick={handleCapture}
                  />
                </div>
              )}
              <div className={videoType == 2 ? "col-12" : "d-none"}>
                <Input
                  label={`Link`}
                  placeholder={`link`}
                  id={`link`}
                  type={`text`}
                  value={videoPath}
                  errorMessage={error.video && error.video}
                  onChange={(e) => {
                    setVideoPath(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        video: `Video Link Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        video: "",
                      });
                    }
                  }}
                />
                {videoPath && (
                  <div className="image-start">
                    <video
                      src={videoPath}
                      alt="banner"
                      controls
                      draggable="false"
                      width={100}
                      height={100}
                      className="m-0"
                    />
                  </div>
                )}
                <div className={"col-12"}>
                  <Input
                    label={`Thumbnail`}
                    id={`image`}
                    type={`file`}
                    accept={`image/*`}
                    errorMessage={error.thumbnail && error.thumbnail}
                    onChange={(e) => handleImage(e)}
                  />
                  {thumbnailPath && (
                    <div className="image-start">
                      <img
                        alt="banner"
                        src={thumbnailPath}
                        draggable="false"
                        width={100}
                        height={100}
                        className="m-0"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {!mongoId ? (
                <>
                  <Button
                    btnName={`Submit`}
                    btnColor={`btnBlackPrime text-white`}
                    style={{ borderRadius: "5px", width: "80px" }}
                    newClass={`me-2`}
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <>
                  <Button
                    btnName={`Update`}
                    btnColor={`btnBlackPrime text-white`}
                    style={{ borderRadius: "5px", width: "80px" }}
                    newClass={`me-2`}
                    onClick={handleSubmit}
                  />
                </>
              )}
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(null, {
  uploadReel,
  updateFakeReel,
  getSellerProduct,
})(SellerReelDiague);
