import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../Component/extra/Button";
import Pagination from "../Component/extra/Pagination";
import Table from "../Component/extra/Table";
import { OPEN_DIALOGUE } from "../Component/store/dialogue/dialogue.type";
import { deletePromoCode, getPromoCode } from "../Component/store/seller/seller.action";
import { warning } from "../util/Alert";
import { formatMoneyVND } from "../util/formatMoney";
import PromoCodeDialog from "./PromoCodeDialog";
const PromoCodePage = (props) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { promoCode } = useSelector((state) => state.seller);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    dispatch(getPromoCode(localStorage.getItem("id")));
  }, [dispatch]);
  useEffect(() => {
    setData(promoCode);
  }, [promoCode]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Delete PromoCode
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deletePromoCode(id,localStorage.getItem("id"));
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },

    { Header: "Code giảm giá", body: "promoCode" },
    {
      Header: "Giảm giá",
      body: "discountAmount",
      Cell: ({ row }) => (
        <>
          {row.discountType === 0 && (
            <>
              <span>
                <b>{formatMoneyVND(row.discountAmount)} </b>
              </span>
            </>
          )}
          {row.discountType === 1 && (
            <>
              <span>
                <b>{row.discountAmount} %</b>
              </span>
            </>
          )}
        </>
      ),
    },
    {
      Header: "Đơn tối thiếu",
      body: "minOrderValue",
      Cell: ({ row }) => (
        <>
          <span>
            <b>{formatMoneyVND(row.minOrderValue)} </b>
          </span>
        </>
      ),
    },
    {
      Header: "Điều kiện",
      body: "conditions",

      Cell: ({ row }) => {
        return (
          <>
            {row.conditions.map((condition, i) => (
              <>
                <div key={i} className=" text-start d-flex">
                  <div style={{ color: "#FF2929" }}>✔ </div>
                  <span className="ms-2">{condition}</span>
                </div>
              </>
            ))}
          </>
        );
      },
    },

    {
      Header: "Ngày tạo",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Chỉnh sửa",
      body: "",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn fs-5`}
            btnIcon={`far fa-edit`}
            style={{
              borderRadius: "5px",
              margin: "auto",
              width: "40px",
              backgroundColor: "#fff",
              color: "#160d98",
            }}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "promoCodeDialog" },
              })
            }
          />
          {dialogue && dialogueType === "promoCodeDialog" && <PromoCodeDialog />}
        </>
      ),
    },
    {
      Header: "Xóa",
      body: "",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn fs-4`}
            style={{
              borderRadius: "5px",
              margin: "auto",
              width: "40px",
              backgroundColor: "#fff",
              color: "#cd2c2c",
            }}
            btnIcon={`bi bi-trash3`}
            onClick={() => handleDelete(row._id)}
          />
        </>
      ),
    },

 
  ];
  return (
    <>
      <div className="mainSellerTable">
        <div className="sellerTable">
          <div className="sellerHeader primeHeader">
            <div className="row">
              <div className="col-10">
                <Button
                  newClass={`whiteFont`}
                  btnColor={`btnBlackPrime`}
                  btnIcon={`fa-solid fa-plus`}
                  btnName={`Thêm`}
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { type: "promoCodeDialog" },
                    });
                  }}
                  style={{ borderRadius: "10px" }}
                />
                {dialogue && dialogueType === "promoCodeDialog" && <PromoCodeDialog />}
              </div>
              <div className="col-2 text-end"></div>
            </div>
          </div>
          <div className="sellerMain">
            <div className="tableMain mt-2">
              <Table
                data={data}
                mapData={mapData}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
              <Pagination
                component="div"
                count={promoCode?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={promoCode?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="sellerFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getPromoCode, deletePromoCode }) (PromoCodePage);
