// GET_SELLER
export const GET_SELLER = "GET_SELLER";
export const GET_SELLER_DROP_DOWN = "GET_SELLER_DROP_DOWN";

// CREATE_SELLER
export const CREATE_SELLER = "CREATE_SELLER";

// UPDATE_SELLER
export const UPDATE_SELLER = "UPDATE_SELLER";

// BLOCK_UNBLOCK_SELLER

export const BLOCK_UNBLOCK_SELLER = "BLOCK_UNBLOCK_SELLER";

// GET_SELLER_PROFILE

export const GET_SELLER_PROFILE = "GET_SELLER_PROFILE";

// GET_SELLER_ORDER

export const GET_SELLER_ORDER = "GET_SELLER_ORDER";

// GET_SELLER_ORDER_DETAIL
export const GET_SELLER_ORDER_DETAIL = "GET_SELLER_ORDER_DETAIL";
export const UPDATE_SELLER_ORDER = "UPDATE_SELLER_ORDER";

// GET_SELLER_PRODUCT

export const GET_SELLER_PRODUCT = "GET_SELLER_PRODUCT";

// GET_SELLER_WALLET

export const GET_SELLER_WALLET = "GET_SELLER_WALLET";
// GET_SELLER_TRANSITION

export const GET_SELLER_TRANSITION = "GET_SELLER_TRANSITION";


// GET_LIVE_SELLER
export const GET_LIVE_SELLER = "GET_LIVE_SELLER";

// GET_LIVE_SELLER_PRODUCT
export const GET_LIVE_SELLER_PRODUCT = "GET_LIVE_SELLER_PRODUCT"

// Login Seller pending loading success error
export const LOGIN_SELLER_PENDING = "LOGIN_SELLER_PENDING";
export const LOGIN_SELLER_SUCCESS = "LOGIN_SELLER_SUCCESS";
export const LOGIN_SELLER_ERROR = "LOGIN_SELLER_ERROR";
// SIGNUP Seller pending loading success error
export const SIGNUP_SELLER = "SIGNUP_SELLER";

 

// Logout Seller
export const LOGOUT_SELLER = "LOGOUT_SELLER";

// GET_SELLER_RÊEEL
export const GET_REEL_BY_SELLER = "GET_REEL_BY_SELLER";

export const UPLOAD_REEL = "UPLOAD_REEL";

export const DELETE_REEL = "DELETE_REEL";

export const GET_REEL_DETAIL = "GET_REEL_DETAIL";

export const ADD_PRODUCT_SELLER = "ADD_PRODUCT_SELLER";
export const UPDATE_PRODUCT_SELLER = "UPDATE_PRODUCT_SELLER";
export const DELETE_PRODUCT_SELLER = "DELETE_PRODUCT_SELLER";

export const PROFILE_UPDATE_SELLER = "PROFILE_UPDATE_SELLER";
export const PROFILE_UPDATE_SELLER_PASSWORD = "PROFILE_UPDATE_SELLER_PASSWORD";
export const PRODUCT_NEW_COLLECTION = "PRODUCT_NEW_COLLECTION";
export const PRODUCT_OUT_OF_STOCK = "PRODUCT_OUT_OF_STOCK";

export const GET_PROMO_CODE = "GET_PROMO_CODE";
export const CREATE_PROMO_CODE = "CREATE_PROMO_CODE";
export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
export const DELETE_PROMO_CODE = "DELETE_PROMO_CODE";